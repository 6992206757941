import React, { useState, useEffect } from "react";
import "./App.css";

const themeIndex = JSON.parse(localStorage.themeIndex || 0) || {};
const colors = ["#2c8b9f", "#d33352", "#ee5253", "#266252", "#00857c"];

let piDigits = ""; // Will hold Pi digits from the file

if (themeIndex.obj !== 0) {
  document.body.style.background = colors[themeIndex.obj];
}

// Change background
function changeBG() {
  let i = themeIndex.obj;
  if (i < colors.length - 1) {
    i += 1;
  } else {
    i = 0;
  }
  document.body.style.background = colors[i];
  themeIndex.obj = i;
  themeIndex.time = new Date().getTime();
  localStorage.themeIndex = JSON.stringify(themeIndex);
}

// Text hider
function displayHide() {
  document.getElementById("info").style.visibility = "hidden";
  document.getElementById("input").style.paddingLeft = "1ch";
  document.getElementById("input").style.paddingRight = "1ch";
}
function displayShow() {
  document.getElementById("info").style.visibility = "visible";
  document.getElementById("input").style.paddingLeft = "0.5ch";
  document.getElementById("input").style.paddingRight = "0.5ch";
}

// Move cursor to end of input
function moveCursorToEnd() {
  const inputElem = document.getElementById("input");
  if (inputElem != null) {
    inputElem.focus();
    inputElem.setSelectionRange(999999999, 999999999);
    console.log("Input activated");
  }
}

// Load event listeners on page load
window.onload = function () {
  document.getElementById("input").addEventListener("click", moveCursorToEnd, true);
  window.addEventListener("dblclick", changeBG, true);
};

function App() {
  const [input, setInput] = useState("");
  const [fileLoaded, setFileLoaded] = useState(false); // Tracks if file is loaded
  const [fileError, setFileError] = useState(""); // Tracks any file load error
  const [n, setN] = useState(-1); // State to track the index of the input in Pi digits

  // Load piDigits.txt on component mount
  useEffect(() => {
    fetch("/piDigits.txt") // Assuming it's in the public folder
      .then((response) => response.text())
      .then((data) => {
        piDigits = data.substring(2); // Strip off "3."
        setFileLoaded(true); // Mark file as loaded
      })
      .catch((err) => {
        console.error("Error loading Pi digits:", err);
        setFileError("Error loading Pi digits.");
      });
  }, []);

  function findInPi(input) {
    if (!fileLoaded) return ""; // Do nothing if file isn't loaded
    const position = piDigits.indexOf(input);
    setN(position); // Set the position in the state
    const isnum = /^\d+$/.test(position);
    console.log(isnum);

    if (input.length === 0) {
      displayShow();
    } else {
      displayHide();
    }

    if (input === "3.") {
      document.getElementById("input").value = "";
      document.getElementById("input").placeholder = "";
    }

    if (position !== -1) {
      return input;
    } else {
      return "";
    }
  }

  return (
    <div className="App">
      {!fileLoaded ? (
        <div>
          <p>Loading Pi digits...</p>
          {fileError && <p>{fileError}</p>}
        </div>
      ) : (
        <div>
          <input
            type="text"
            value={input}
            onChange={(e) => {
              findInPi(e.target.value);
              setInput(e.target.value);
            }}
            className="input"
            placeholder="𝞹"
            name="input"
            id="input"
            inputMode="numeric"
            autoFocus={true}
          />
          <div className="output" id="output">
            {input === "" ? (
              <p style={{ userSelect: "none", cursor: "default" }}>
                Enter as many digits of pi as possible!
              </p>
            ) : (
              <div>
                {n === -1 ? (
                  <p>INCORRECT!</p>
                ) : (
                  <p>
                    starting at:{" "}
                    <span style={{ color: " #19222d" }}>#{n + 1}</span> - at
                    position:{" "}
                    <span style={{ color: " #19222d" }}>
                      #{n + input.length}
                    </span>
                  </p>
                )}
                <p id="output_numbers">
                  <span style={{ color: " #19222d" }}>
                    {input
                      .replace(/(\d{10})/g, "$1 ")
                      .replace(/(^\s+|\s+$)/, "")}
                  </span>
                  <span id="dotdotdot">{"..."}</span>
                </p>
              </div>
            )}
            <p className="info" id="info">
              <span style={{ opacity: "0.8em" }}>
                {" "}
                Start anywhere after "3." within the first one million digits
              </span>
              <br />
              <span style={{ fontSize: "0.8em", opacity: "0.55" }}>
                Double click to change theme
              </span>
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
